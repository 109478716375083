import React from 'react';
import SUV from "../images/esmartad.gif"
import { GiTargetShot } from 'react-icons/gi';  // For Mission
import { AiOutlineEye } from 'react-icons/ai';
import HeaderImage from "../images/Default_Street_photography_style_image_featuring_a_man_and_a_w_4.jpg"; // Import the image
import felixImage from "../images/felix.jpg"
import douglasImage from "../images/douglas.jpg"
import jeslordImage from "../images/jeslord.jpg"
import amofaImage from "../images/amofa.jpg"

export default function Header() {
    return (
        <>
            <header
                style={{
                    backgroundImage: `url(${HeaderImage})`, // Use the imported image
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center', // Optional: Adjust background positioning
                    width: '100vw',
                    height: "750px",
                    position: "relative"
                }}
                id='home'
                className="w-screen header py-8">
                <div className='container mx-auto flex flex-col justify-center items-center bg-opacity-20 bg-black  text-white mt-44'>
                    <div className='text-wrapper overflow-hidden text-5xl md:text-7xl font-bold'>
                        <span className="letter drop-shadow-lg animate-slide-in-delay">E</span>
                        <span className="letter drop-shadow-lg z-50 animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.1s" }}>S</span>
                        <span className="letter drop-shadow-lg z-50 animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.2s" }}>M</span>
                        <span className="letter drop-shadow-lg z-50 animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.3s" }}>A</span>
                        <span className="letter drop-shadow-lg z-50 animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.4s" }}>R</span>
                        <span className="letter drop-shadow-lg z-50 animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.5s" }}>T</span>
                        <span className="letter drop-shadow-lg z-50 animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.6s" }}>R</span>
                        <span className="letter drop-shadow-lg z-50 animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.7s" }}>I</span>
                        <span className="letter drop-shadow-lg z-50 animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.8s" }}>D</span>
                        <span className="letter drop-shadow-lg z-50 animate-slide-in-delay" style={{ opacity: "0", animationDelay: "0.9s" }}>E</span>
                        <span className="letter drop-shadow-lg z-50 animate-slide-in-delay" style={{ opacity: "0", animationDelay: "1s" }}>S</span>
                    </div>
                    <h1 className='text-xl text-center my-8 text-stone-100'>We provide eco-friendly ride-hailing services targetting 30 Africa countries with 50 economic viable cities and beyond Africa. We are committed to making transportation smarter and greener.</h1>
                </div>
            </header>

            {/* about us */}
            <section id='about-us' className='w-screen'>
                <div className='container w-screen mx-auto px-8 py-24'>
                    <h2 className='text-center heading-text'>Get to know about us</h2>
                    <div className='flex flex-col-reverse lg:flex-row'>
                        <div className='text mt-8 px-8'>
                            <p className='paragraph lg:my-14'>
                                E-Smart Rides offer eco-friendly ride-hailing mobility services that provide services in Ghana and beyond. Customers will have the opportunity to make requests through a hotline or smartphone-based app and enjoy the most affordable, safest, intelligent, and comfortable transportation service. E-Smart Rides will also provide advertisement services through mounted LED screens on our electric SUVs.
                            </p>
                        </div>
                        <div className='image'>
                            <img src={SUV} alt='suv' width={10000} />
                        </div>
                    </div>
                    {/* mission and vision */}
                    <div className='flex flex-col lg:flex-row'>
                        <div className='mission-card rounded-lg shadow-lg p-4 m-4 text-xl font-black color-changing-bg'>
                            <div className='flex items-center justify-center'>
                                <GiTargetShot size={50} className='mr-4 text-white' />
                                <span className='uppercase text-white text-3xl'>Our Mission</span>
                            </div>
                            <p className='mt-4 text-black'>
                                is to be the leading, eco-friendly, and innovative ride-hailing company in Africa and a lasting organization that provides reliability.
                            </p>
                        </div>
                        <div className='vision-card rounded-lg shadow-lg p-4 m-4 text-xl font-black color-changing-bg'>
                            <div className='flex items-center justify-center'>
                                <AiOutlineEye size={50} className='mr-4 text-white' />
                                <span className='uppercase text-white text-3xl'>Our Vision</span>
                            </div>
                            <p className='mt-4 text-black'>
                                is to be the most environmentally sustainable transportation company with the safest and most affordable accessibility to premium rides.
                            </p>
                        </div>
                    </div>
                    {/* the team */}
                    <div className='the-team grid grid-cols-1 md:grid-cols-4 px-4 gap-8'>
                        <h3 className='md:col-span-4 text-center font-black text-4xl mt-32 my-4'>Meet The <span className='text-bg'>Team</span></h3>
                        <div className='team-profile text-center rounded-full flex flex-col gap-y-2'>
                            <img src={douglasImage} alt='douglas baryeh' className='rounded-full shadow-lg' />
                            <p className='font-black text-2xl md:text-2xl lg:text-3xl uppercase'>Douglas Baryeh</p>
                            <p className='font-semibold text-stone-800 md:text-xl '>FOUNDER / CEO</p>
                            <p className='font-semibold text-stone-600 '>LinkedIn: Douglas Baryeh</p>
                        </div>
                        <div className='team-profile text-center rounded-full flex flex-col gap-y-2'>
                            <img src={amofaImage} alt='george amofa' className='rounded-full shadow-lg' />
                            <p className='font-black text-2xl md:text-2xl lg:text-3xl uppercase'>George Amofa</p>
                            <p className='font-semibold text-stone-800 md:text-xl '>CO-FOUNDER / FUNDRAISING / PARTNERSHIPS</p>
                            <p className='font-semibold text-stone-600 '>LinkedIn: George Amofa</p>
                        </div>
                        <div className='team-profile text-center rounded-full flex flex-col gap-y-2'>
                            <img src={felixImage} alt='felix adusei' className='rounded-full shadow-lg' />
                            <p className='font-black text-2xl md:text-2xl lg:text-3xl uppercase'>Felix Kofi Adusei</p>
                            <p className='font-semibold text-stone-800 md:text-xl '>C.O.O</p>
                            <p className='font-semibold text-stone-600 '>LinkedIn: Felix Kofi Adusei</p>
                        </div>
                        <div className='team-profile text-center rounded-full flex flex-col gap-y-2'>
                            <img src={jeslordImage} alt='jeslord afriyie' className='rounded-full shadow-lg' />
                            <p className='font-black text-2xl md:text-2xl lg:text-3xl uppercase'>Jeslord afriyie</p>
                            <p className='font-semibold text-stone-800 md:text-xl '>Finance / Admin</p>
                            <p className='font-semibold text-stone-600 '>LinkedIn: Afriyie Jeslord</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

import React from 'react';
import { FaTaxi, FaBullhorn, FaCarAlt, FaBus } from 'react-icons/fa'; // Use FaCarAlt for a different car icon

export default function Services() {
    return (
        <main id='services' className="services-page mt-10 text-black py-12">
            <div className="container px-8 w-screen mx-auto overflow-x-hidden pb-10">
                <div className="services p-8">
                    <h3 className="heading-text text-center">Our <span className='text-bg'>Services</span></h3>
                    <p className="text-xl leading-9 text-stone-700 text-center mb-12">
                        Discover the range of services we offer to meet your needs.
                    </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {/* Ride Hailing App Card */}
                    <div className="service-card bg-white p-4 rounded-lg shadow-xl">
                        <FaTaxi size={50} className="w-full h-40 text-center text-blue-300 mx-auto" /> {/* Replaced placeholder with Taxi Icon */}
                        <div className="p-4">
                            <h4 className="text-2xl font-black">Ride Hailing App</h4>
                            <p className="paragraph text-sm mt-2">
                                Our ride-hailing app delivers reliable and convenient transportation solutions tailored to meet your needs. Whether you're looking for a quick ride across town or planning a long-distance journey, our app ensures a seamless and comfortable experience. With a user-friendly interface, real-time tracking, and a fleet of well-maintained vehicles, we prioritize your safety and satisfaction while making travel effortless and enjoyable.
                            </p>
                        </div>
                    </div>

                    {/* Intercity Transport Card */}
                    <div className="service-card bg-white p-4 rounded-lg shadow-xl">
                        <FaBus size={50} className="w-full h-40 text-center text-green-500 mx-auto" /> {/* Replaced placeholder with Bus Icon */}
                        <div className="p-4">
                            <h4 className="text-2xl font-black">Intercity Transport</h4>
                            <p className="paragraph text-sm mt-2">
                                Experience convenient and reliable intercity transport with our fleet of well-maintained mini-buses, connecting Accra, Kumasi, and Takoradi. Our service ensures comfort and safety while providing an affordable travel solution for long-distance journeys. Real-time updates, friendly drivers, and punctual departures make every trip a seamless experience.
                            </p>
                        </div>
                    </div>

                    
                    {/* Advertisement Card */}
                    <div className="service-card bg-white p-4 rounded-lg shadow-xl">
                        <FaBullhorn size={50} className="w-full h-40 text-center text-lime-400 mx-auto" /> {/* Replaced placeholder with Bullhorn Icon */}
                        <div className="p-4">
                            <h4 className="text-2xl font-black">Advertisement</h4>
                            <p className="paragraph text-sm mt-2">
                                Maximize your brand's exposure with our innovative advertising solutions. We offer a range of options, from digital ads to strategic promotional campaigns, all designed to effectively reach and engage your target audience. Our unique advertising platform features LED screens mounted on our ride-hailing SUVs, ensuring your message is seen by millions of potential customers during their daily commutes. Enhance your brand visibility and drive impactful results with our dynamic advertising services.
                            </p>
                        </div>
                    </div>

                    {/* Rental and Dealerships Card */}
                    <div className="service-card bg-white p-4 rounded-lg shadow-xl">
                    <FaCarAlt size={50} className="w-full h-40 text-center text-amber-400 mx-auto" /> {/* Replaced FaCar with FaCarAlt */}
                    <div className="p-4">
                            <h4 className="text-2xl font-black">Rental and Dealerships</h4>
                            <p className="paragraph text-sm mt-2">
                                {/* Our rental and dealership services provide you with flexible options to own or lease top-quality vehicles. Whether you're looking to buy a car or simply rent one for a short term, we offer competitive prices and a wide selection of vehicles to choose from. Our dealership ensures that all vehicles meet high standards of safety and performance, offering you peace of mind with every purchase or rental. */}
                                Experience ultimate flexibility with our hourly rental service. Our hourly rentals give you the freedom to rent on your schedule. Pay only for the time you need, with easy booking and real-time availability at your fingertips.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
